/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, Image, Subtitle, ContactForm } from '@swp/components'
import SiteHeader from '../../components/de/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="pb--80 pt--30" name={"einleitung"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--128 ls--001" content={"Adam & Emilia"}>
              </Title>

              <Text className="text-box fs--30 mt--0" content={"Handgemachte Mode"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-92byrv bg--bottom --full pb--80 pt--80" name={"bild"} fullscreen={true} css={css`
        
    background-image: url(https://cdn.swbpg.com/t/i/template/81/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/81/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/81/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/81/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/81/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/81/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/81/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/81/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--4 flex--bottom" columns={"4"}>
            
            <ColumnWrapper style={{"maxWidth":400,"backgroundColor":"var(--color-dominant)"}}>
              
              <Text className="text-box" content={"Bluse XS – XXL"}>
              </Text>

              <Title className="title-box" content={"699€"}>
              </Title>

              <Button className="btn-box btn-box--hvr4 btn-box--shadow5" href={"#kontakt"} content={"jetzt Bestellen"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":400,"backgroundColor":"var(--color-dominant)"}}>
              
              <Text className="text-box" content={"Hose XS – XXL"}>
              </Text>

              <Title className="title-box" content={"949€"}>
              </Title>

              <Button className="btn-box btn-box--hvr4 btn-box--shadow5" href={"#kontakt"} content={"jetzt Bestellen"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":400,"backgroundColor":"var(--color-dominant)"}}>
              
              <Text className="text-box" content={"Mantel XS – XXL"}>
              </Text>

              <Title className="title-box" content={"2,599€"}>
              </Title>

              <Button className="btn-box btn-box--hvr4 btn-box--shadow5" href={"#kontakt"} content={"jetzt Bestellen"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":400,"backgroundColor":"var(--color-dominant)"}}>
              
              <Text className="text-box" content={"Unterhemd XS – XXL"}>
              </Text>

              <Title className="title-box" content={"299€"}>
              </Title>

              <Button className="btn-box btn-box--hvr4 btn-box--shadow5" href={"#kontakt"} content={"jetzt Bestellen"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"artikel-1"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"der Natur entgegen — uns entgegen"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":1050}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"artikel-2"} layout={"l1"}>
          
          <ColumnWrap className="column__flex el--3" columns={"3"}>
            
            <ColumnWrapper className="mb--40">
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/7941/3177d376572e4ba3a8deb75b790d73f3_s=660x_.jpg"} svg={false} ratio={"2:3"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/7941/3177d376572e4ba3a8deb75b790d73f3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7941/3177d376572e4ba3a8deb75b790d73f3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7941/3177d376572e4ba3a8deb75b790d73f3_s=860x_.jpg 860w"} content={null}>
              </Image>

              <Text className="text-box mt--20" content={"Hose XS – XXL"}>
              </Text>

              <Title className="title-box" content={"699€"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="mb--40">
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/7941/f39fcba32db1445e8c8e2fa329aebc3b_s=860x_.jpg"} svg={false} ratio={"2:3"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/7941/f39fcba32db1445e8c8e2fa329aebc3b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7941/f39fcba32db1445e8c8e2fa329aebc3b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7941/f39fcba32db1445e8c8e2fa329aebc3b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7941/f39fcba32db1445e8c8e2fa329aebc3b_s=1400x_.jpg 1400w"} content={null}>
              </Image>

              <Text className="text-box mt--20" content={"Bluse XS – XXL"}>
              </Text>

              <Title className="title-box" content={"949€"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="mb--40">
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/81/img-2_s=350x_.jpg"} svg={false} ratio={"2:3"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/81/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/81/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/81/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/81/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/81/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Text className="text-box mt--20" content={"Bluse XS – XXL"}>
              </Text>

              <Title className="title-box" content={"299€"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="mb--40">
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/81/img-3_s=350x_.jpg"} svg={false} ratio={"2:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/81/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/81/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/81/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/81/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/81/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Text className="text-box mt--20" content={"Mantel XS – XXL"}>
              </Text>

              <Title className="title-box" content={"299€"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="mb--40">
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/7941/3177d376572e4ba3a8deb75b790d73f3_s=660x_.jpg"} svg={false} ratio={"2:3"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/7941/3177d376572e4ba3a8deb75b790d73f3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7941/3177d376572e4ba3a8deb75b790d73f3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7941/3177d376572e4ba3a8deb75b790d73f3_s=860x_.jpg 860w"} content={null}>
              </Image>

              <Text className="text-box mt--20" content={"Hose XS – XXL"}>
              </Text>

              <Title className="title-box" content={"949€"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="mb--40">
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/7941/f39fcba32db1445e8c8e2fa329aebc3b_s=860x_.jpg"} svg={false} ratio={"2:3"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/7941/f39fcba32db1445e8c8e2fa329aebc3b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7941/f39fcba32db1445e8c8e2fa329aebc3b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7941/f39fcba32db1445e8c8e2fa329aebc3b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7941/f39fcba32db1445e8c8e2fa329aebc3b_s=1400x_.jpg 1400w"} content={null}>
              </Image>

              <Text className="text-box mt--20" content={"Bluse XS – XXL"}>
              </Text>

              <Title className="title-box" content={"699€"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-175xyfo bg--center --full --parallax mt--60 pb--80 pt--80" name={"bild-2"} parallax={true} fullscreen={true} css={css`
        
    background-image: url(https://cdn.swbpg.com/t/i/template/81/img-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/81/img-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/81/img-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/81/img-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/81/img-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/81/img-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/81/img-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/81/img-2_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex el--4 flex--bottom" columns={"4"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":400,"backgroundColor":"var(--color-dominant)"}}>
              
              <Text className="text-box" content={"Bluse XS – XXL"}>
              </Text>

              <Title className="title-box" content={"699€"}>
              </Title>

              <Button className="btn-box btn-box--hvr4 btn-box--shadow5" href={"#kontakt"} content={"jetzt Bestellen"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"information"} layout={"l1"}>
          
          <ColumnWrap className="column__flex el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":600}}>
              
              <Title className="title-box" content={"Durch eine bewusste Anstrengung — sauber & unberührt zu bleiben"}>
              </Title>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

              <Button className="btn-box btn-box--hvr4 btn-box--shadow5" href={"#[[T72SecName8]]"} content={"Mehr Informationen bekommen"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/81/fullscreen_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/81/fullscreen_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/81/fullscreen_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/81/fullscreen_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/81/fullscreen_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/81/fullscreen_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-lf57v4 bg--center --full --parallax pb--80 pt--80" name={"bild-3"} parallax={true} fullscreen={true} css={css`
        
    background-image: url(https://cdn.swbpg.com/t/i/template/81/img-3_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/81/img-3_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/81/img-3_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/81/img-3_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/81/img-3_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/81/img-3_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/81/img-3_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/81/img-3_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex el--4 flex--bottom" columns={"4"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":400,"backgroundColor":"var(--color-dominant)"}}>
              
              <Text className="text-box" content={"Bluse XS – XXL"}>
              </Text>

              <Title className="title-box" content={"699€"}>
              </Title>

              <Button className="btn-box btn-box--hvr4 btn-box--shadow5" href={"#kontakt"} content={"jetzt Bestellen"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"kontakt"}>
          
          <ColumnWrap className="column__flex el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":600}}>
              
              <Title className="title-box" content={"Haben Sie fragen?"}>
              </Title>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks ändern."}>
              </Text>

              <Subtitle className="subtitle-box" content={"+49 797 811 2X05"}>
              </Subtitle>

              <Subtitle className="subtitle-box" content={"info@vase-stranky.com"}>
              </Subtitle>

              <Subtitle className="subtitle-box" content={"Oeder Weg 22, Frankfurt a/M."}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2" action={"/contact"} fields={[{"name":"Ganze Name","type":"text","required":true,"placeholder":"Bitte tragen Sie Ihren Namen ein"},{"name":"Telefon Nr.","type":"text","placeholder":"Telefon Nr."},{"name":"Nachricht","type":"textarea","placeholder":"Bitte geben Sie Text Ihrer Nachricht ein"},{"name":"Versenden","type":"submit"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1cwebgx pb--80 pt--80" name={"partei-1"} style={{"backgroundColor":"var(--color-supplementary)"}} layout={"l1"} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/81/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/81/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/81/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/81/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/81/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/81/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/81/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/81/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex el--3" columns={"3"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style='color: white'>Adam & Emilia</span>"}>
              </Title>

              <Text className="text-box" content={"<span style='color: white'>Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style='color: white'>Kontakt</span>"}>
              </Title>

              <Text className="text-box" content={"<span style='color: white'>+49 797 811 2X05<br>info@vase-stranky.com<br>Oeder Weg 22<br>Frankfurt a/M.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/o/7941/c3219b57a33248099263a4fef26b3968.svg"} svg={false} href={"#"} sizes={"100vw"} style={{"maxWidth":32}} srcSet={""} content={null}>
              </Image>

              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/o/7941/71344508b0114603abad36348df7c464.svg"} svg={false} href={"#"} sizes={"100vw"} style={{"maxWidth":32}} srcSet={""} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" name={"partei-2"} style={{"backgroundColor":"var(--color-supplementary)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex el--2" columns={"2"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Text className="text-box" content={"<span style='color: white'>Erstellt mit <a href=\"https://saywebpage.com\">saywebpage.com</a></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Text className="text-box" content={"<span style='color: var(--color-dominant)'>(C) 2021</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}